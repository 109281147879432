/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components';

import Header from "./header"
import "./layout.css"
import Logo from './svgs/logo_tri.js';

import {colors} from '../helper'

const Wrap = styled.div`
  position:relative;
`
const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em;
  margin: 0 auto;
  background-color: ${colors.primary};
  color: #ffffff;
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Wrap>
      <Header siteTitle={data.site.siteMetadata.title} />
      
        <main>{children}</main>
        <Footer>
          <p>
          © Tipis of London {new Date().getFullYear()}</p>
          <Logo />
        </Footer>
      
    </Wrap>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
