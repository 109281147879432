import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="48"
      data-name="Group 17"
      viewBox="0 0 55 48"
    >
      <g fill="none" data-name="Polygon 3">
        <path d="M27.5 0L55 48H0z"></path>
        <path
          fill="#fff"
          d="M27.5 4.023L3.45 46h48.1L27.5 4.023M27.5 0L55 48H0L27.5 0z"
        ></path>
      </g>
      <path
        fill="none"
        stroke="#fff"
        strokeWidth="2"
        d="M18.652 47.035l16.831-29.8"
        data-name="Path 2"
      ></path>
      <path
        fill="none"
        stroke="#fff"
        strokeWidth="2"
        d="M0 0L10.718 0"
        data-name="Line 5"
        transform="translate(22.159 11.442)"
      ></path>
      <path
        fill="none"
        stroke="#fff"
        strokeWidth="2"
        d="M0 0L8.69 15.063"
        data-name="Line 6"
        transform="translate(27.663 32.008)"
      ></path>
    </svg>
  );
}

export default Icon;
