import React from "react";

function Icon({className}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="320"
      height="48"
      viewBox="0 0 320 48"
      {...{className}}
    >
      <g data-name="Group 16" transform="translate(-272 -1337)">
        <g fill="none" data-name="Group 11" transform="translate(272 1337)">
          <g data-name="Polygon 3">
            <path d="M27.5 0L55 48H0z"></path>
            <path
              fill="#fff"
              d="M27.5 4.023L3.45 46h48.1L27.5 4.023M27.5 0L55 48H0L27.5 0z"
            ></path>
          </g>
          <path
            stroke="#fff"
            strokeWidth="2"
            d="M18.652 47.035l16.831-29.8"
            data-name="Path 2"
          ></path>
          <path
            stroke="#fff"
            strokeWidth="2"
            d="M0 0L10.718 0"
            data-name="Line 5"
            transform="translate(22.159 11.442)"
          ></path>
          <path
            stroke="#fff"
            strokeWidth="2"
            d="M0 0L8.69 15.063"
            data-name="Line 6"
            transform="translate(27.663 32.008)"
          ></path>
        </g>
        <text
          fill="#fff"
          data-name="Tipis Of London"
          fontFamily="Josefin Sans"
          fontSize="28"
          letterSpacing=".1em"
          transform="translate(347 1372)"
        >
          <tspan x="0" y="0">
            Tipis Of London
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default Icon;
